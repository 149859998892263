<template>
    <div v-click-outside="onClickOutside" class="mw-form-select">
        <label :for="props.id">{{ props.label }}</label>
        <select
            :id="props.id"
            v-model="selectedValues"
            :name="props.multiple ? `${props.name}[]` : props.name"
            :multiple="props.multiple"
            :class="{ 'sr-only': props.multiple }"
        >
            <option v-for="value in values" :key="value.value" :value="value.value" v-text="value.label" />
        </select>

        <button v-if="props.multiple" type="button" class="mw-form-select__select truncate" @click.prevent="onToggle">
            <span>{{ selectedValuesDisplay }}</span>
        </button>

        <transition name="fade-zoom">
            <ul
                v-if="isOpen"
                class="absolute top-[calc(100%+1rem)] left-0 w-full min-w-[16rem] py-4 bg-white dark:embedded:bg-black rounded-xl shadow-lg z-40"
            >
                <li
                    v-for="value in values"
                    :key="value.value"
                    class="block w-full px-4 py-2"
                    :class="props.swatches ? 'mw-form-swatch' : 'mw-form-checkbox'"
                    :style="{ '--swatch': value.color }"
                >
                    <input
                        :id="getId(value.value)"
                        v-model="selectedValues"
                        :value="value.value"
                        type="checkbox"
                    />
                    <label :for="getId(value.value)">{{ value.label }}</label>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script setup>
    import {
        ref,
        computed,
    } from 'vue';

    const props = defineProps({
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        allSelected: {
            type: String,
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        swatches: {
            type: Boolean,
            default: false,
        },
        values: {
            type: Array,
            default: () => [],
        },
        defaultValue: {
            type: [ Array, String ],
            default: props => (props.multiple ? [] : null),
        },
    });

    const isOpen = ref(false);

    const onToggle = () => {
        isOpen.value = !isOpen.value;
    };

    const onClickOutside = () => {
        isOpen.value = false;
    };

    const selectedValues = ref(props.defaultValue);

    const selectedValuesDisplay = computed(() => {
        if (!props.multiple) {
            const selectedValue = props.values.find(option => option.value === selectedValues.value);
            return selectedValue ? selectedValue.label : null;
        }

        if (selectedValues.value.length === 0 || selectedValues.value.length === props.values.length) {
            return props.allSelected;
        }

        return selectedValues.value.map(value => {
            const selectedValue = props.values.find(option => option.value === value);
            return selectedValue ? selectedValue.label : null;
        }).filter(value => value !== null).join(', ');
    });

    const getId = id => {
        const newId = id.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
        return `${props.id}-${newId}`;
    };
</script>
