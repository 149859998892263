<template>
    <section>
        <slot
            v-bind="{
                colors,
                sizes,
                images,
                selectedSize,
                setSelectedSize,
                selectedColor,
                setSelectedColor,
                thumbsSwiper,
                setThumbsSwiper,
                selectedVariant,
            }"
        />
    </section>
</template>

<script setup>
    import { ref, computed } from 'vue';

    const props = defineProps({
        variants: {
            type: Array,
            required: true,
        },
    });

    const sizes = computed(() => props.variants.reduce((acc, variant) => {
        if (!acc.find(size => size.value === variant.size.value)) {
            acc.push(variant.size);
        }
        return acc;
    }, []));

    const colors = computed(() => props.variants.reduce((acc, variant) => {
        if (!acc.find(color => color.value === variant.color.value)) {
            acc.push(variant.color);
        }
        return acc;
    }, []));

    const thumbsSwiper = ref(null);
    const setThumbsSwiper = swiper => {
        thumbsSwiper.value = swiper;
    };

    const selectedSize = ref(props.variants[0].size.value);
    const setSelectedSize = size => selectedSize.value = size;

    const selectedColor = ref(props.variants[0].color.value);
    const setSelectedColor = color => selectedColor.value = color;

    const selectedVariant = computed(() => props.variants.find(variant => variant.size.value === selectedSize.value && variant.color.value === selectedColor.value));

    const images = computed(() => {
        const images = [];

        if (selectedVariant.value.customImage) {
            images.push(selectedVariant.value.customImage);
        }

        if (selectedVariant.value.imageFront) {
            images.push(selectedVariant.value.imageFront);
        }

        if (selectedVariant.value.imageBack) {
            images.push(selectedVariant.value.imageBack);
        }

        return images;
    });
</script>
