<template>
    <div>
        <slot :active-node="activeNode" :set-active-node="setActiveNode" />
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const props = defineProps({
        initialNode: {
            type: Number,
            default: null,
        },
    });

    const activeNode = ref(props.initialNode);

    const setActiveNode = node => {
        activeNode.value = node;
    };
</script>
