<template>
    <section ref="root">
        <slot
            :on-filter-change="onFilterChangeDebounced"
            v-bind="{
                onFilterChange: onFilterChangeDebounced,
                activeNodes,
                toggleActiveNode,
                isFilterOpen,
                toggleFilter,
            }"
        />
    </section>
</template>

<script setup>
    import qs from 'qs';
    import debounce from 'lodash.debounce';
    import { ref, onMounted, onBeforeUnmount } from 'vue';

    const root = ref(null);
    const props = defineProps({
        embedded: {
            type: Boolean,
            default: false,
        },
        initialNodes: {
            type: Array,
            default: () => [],
        },
    });

    const onFilterChange = async rawParams => {
        const url = qs.stringify(rawParams, {
            arrayFormat: 'brackets',
            encodeValuesOnly: true,
            addQueryPrefix: true,
        });

        if (!props.embedded) {
            history.pushState({ filter: rawParams }, '', url);
        }

        const response = await fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });

        const html = await response.text();

        const el = root.value.querySelector('#list');
        el.innerHTML = html;
    };

    const onFilterChangeDebounced = debounce(async event => {
        const form = event.target.closest('form');
        const formData = new FormData(form);

        const params = new URLSearchParams(formData);
        const rawParams = qs.parse(params.toString());
        const currentParams = qs.parse(location.search, { ignoreQueryPrefix: true });

        if (currentParams.embedded) {
            const embeddedKeys = [
                'embedded',
                'accent',
                'border',
                'cartBackground',
                'scheme',
                'store',
            ];

            for (const key of embeddedKeys) {
                rawParams[key] = currentParams[key];
            }
        }

        await onFilterChange(rawParams);
    }, 750);

    const setFormValues = filter => {
        for (const key in filter) {
            if (Object.prototype.hasOwnProperty.call(filter, key)) {
                const value = filter[key];

                for (const form of root.value.querySelectorAll('form')) {
                    if (typeof value === 'object') {
                        for (const el of form.querySelectorAll(`[name="${key}[]"]`)) {
                            if (el.nodeName === 'INPUT') {
                                el.checked = value.includes(el.value);
                            } else {
                                for (const opt of el.querySelectorAll('option')) {
                                    opt.checked = value.includes(opt.value);
                                }
                            }
                        }
                    } else {
                        const el = form.querySelector(`[name="${key}"]`);
                        el.value = filter[key];
                    }
                }
            }
        }
    };

    const onPopState = event => {
        if (!event.state || typeof event.state.filter === 'undefined') {
            return;
        }

        event.preventDefault();

        onFilterChange(event.state.filter);
        setFormValues(event.state.filter);
    };

    const activeNodes = ref(props.initialNodes);
    const toggleActiveNode = node => {
        const index = activeNodes.value.findIndex(activeNode => activeNode === node);

        if (index === -1) {
            activeNodes.value.push(node);
        } else {
            activeNodes.value.splice(index, 1);
        }
    };

    const isFilterOpen = ref(false);
    const toggleFilter = () => {
        isFilterOpen.value = !isFilterOpen.value;
    };

    if (!props.embedded) {
        onMounted(() => {
            window.addEventListener('popstate', onPopState);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('popstate', onPopState);
        });
    }
</script>
